import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Typed from "react-typed";
import "./App.css";

function App() {
  return (
    <div
      className="app"
      style={{
        background: "url(images/hm.png)",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}
    >
      <Container style={{ height: "100%" }}>
        <Row style={{ paddingTop: "6em", height: "100%" }}>
          <Col
            sm={12}
            md={6}
            lg={5}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "70%",
            }}
          >
            <div className="bodyText">Hi!</div>
            <div className="bodyText">
              <Typed strings={["I'm Isabelle Zheng."]} typeSpeed={60} />
            </div>
            <div className="bodyText">
              I work on software at{" "}
              <a
                href="https://watershed.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Watershed
              </a>
              , helping decarbonize the economy. I also care about startups,
              food, cities, and art.
            </div>
            <div className="bodyText">
              Before this, I was at Harvard studying Computer Science.
            </div>
            <div className="smallText">
              <div>
                Past: Software @{" "}
                <a
                  href="https://bubble.io/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Bubble
                </a>
                ,{" "}
                <a
                  href="https://stripe.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Stripe
                </a>
                ,{" "}
                <a
                  href="https://whist.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Whist
                </a>
                , and{" "}
                <a
                  href="https://www.facebook.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  FB
                </a>
                ; Director @{" "}
                <a
                  href="https://www.collegiate.vc/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Collegiate
                </a>
                ; Cupid @{" "}
                <a
                  href="https://datamatch.me/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Datamatch
                </a>
                ; Product @{" "}
                <a
                  href="https://www.nylas.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Nylas
                </a>
              </div>
            </div>
            <div className="smallText">
              Projects:{" "}
              <a
                href="https://www.theharvardadvocate.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Advocate
              </a>{" "}
              |{" "}
              <a
                href="https://sol.datamatch.me/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Love
              </a>{" "}
              |{" "}
              <a
                href="http://inhindsight.art/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Map
              </a>{" "}
              |{" "}
              <a
                href="https://the-voting-methods.web.app/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Voting
              </a>{" "}
              |{" "}
              <a
                href="https://www.congregate.live/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Events
              </a>{" "}
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={{ span: 5, offset: 2 }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>
              <a
                href="https://isabellez.substack.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Blog
              </a>
            </span>
            <span>
              <a href="mailto:isa.zheng@gmail.com">Email</a>
            </span>

            <span>
              <a
                href="https://www.linkedin.com/in/isabellez/"
                rel="noopener noreferrer"
                target="_blank"
              >
                LinkedIn
              </a>
            </span>
            <span>
              <a
                href="https://github.com/xisabao"
                rel="noopener noreferrer"
                target="_blank"
              >
                GitHub
              </a>
            </span>
            <span>
              <a
                href="https://twitter.com/isabelleszheng"
                rel="noopener noreferrer"
                target="_blank"
              >
                Twitter
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
